@import '@/styles/_variable.scss';
@import '@/styles/mixin.scss';

.pageLanding {
    position: relative;
    text-align: center;
    padding-block-end: 9.153em;
    padding-block-start: 20px;
    --target-color: var(--color-yellow);

    &.pageReady {
        h1>span {
            animation: a-pl-1 1s cubic-bezier(0.68, -0.12, 0, 1.15);
            animation-delay: 0.3s;
            animation-fill-mode: both;
        }
    }

    .wrap {
        padding-inline: 3em;
    }

    h1 {
        font-size: 1.8461em;
        line-height: 1.4166;
        color: var(--color-black);
        font-family: var(--font-parisienne);
        font-weight: 400;
        margin-bottom: 0.2em;
        line-height: 42px;
        height: 42px;
        position: relative;

        >span {
            width: 0%;
        }

        span {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            overflow: hidden;

            span {
                width: calc(100vw - 3.2em);

                @include pad-l {
                    width: calc(var(--page-max-size) - 3.2em);

                }
            }
        }
    }

    .contnet {
        background-color: var(--color-white);
        color: var(--color-dark-gray);
        max-width: 85%;
        margin-inline: auto;
        border-radius: var(--size-12);
        padding: var(--size-18) var(--size-20);
        margin-block-end: 2.769em;
        opacity: 0;
        animation: a-pl-3 0.8s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-delay: 1s;
        animation-fill-mode: both;
    }

    .ellipseTitle {
        font-family: var(--font-josefinslab);
        font-weight: 600;
        font-size: 3.846em;
        color: var(--color-white);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        margin-inline: auto;
        margin-block-end: 0.1em;
        background-color: var(--target-color);
        border-radius: 50%;
        line-height: 0.58;
        padding-top: 0.15em;
        box-shadow: 0.08em 0.08em 0 0 rgba($color: #000000, $alpha: 0.1);
        transform: rotateX(90deg);
        animation: a-pl-2 1s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-delay: 0.5s;
        animation-fill-mode: both;
    }

    h2 {
        font-weight: 300;
        font-size: 3.0769em;
        line-height: 1.15;
        margin-bottom: 0.625em;
        opacity: 0;
        animation: a-pl-3 0.8s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-delay: 0.8s;
        animation-fill-mode: both;
        margin-inline: -0.5em;
    }

    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        animation: a-pl-5 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-fill-mode: both;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    nav {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.2em;

        a {
            text-align: left;
            box-shadow: var(--box-shadow);
            background-color: var(--target-color);
            color: var(--color-white);
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            font-size: 2em;
            line-height: 1.1;
            font-weight: 300;
            border-radius: 0.861em;
            padding-inline-start: 0.8461em;
            padding-inline-end: 0.384em;
            padding-block: 0.384em;

            opacity: 0;
            animation: a-pl-4 0.8s cubic-bezier(0.68, -0.12, 0, 1.15);
            animation-delay: 1.2s;
            animation-fill-mode: both;

            &:nth-child(2) {
                animation-delay: 1.4s;
            }

            &:nth-child(3) {
                animation-delay: 1.6s;
            }

            &:nth-child(4) {
                animation-delay: 1.8s;
            }

            &:nth-child(5) {
                animation-delay: 2s;
            }

            span {
                flex-grow: 1;
            }

            svg {
                --circle-color: var(--color-white);
                --arrow-color: var(--target-color);
                block-size: 1.1538em;
                inline-size: 1.1538em;
            }
        }
    }

    .wrap {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }
}

@keyframes a-pl-1 {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes a-pl-2 {
    0% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes a-pl-3 {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes a-pl-4 {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes a-pl-5 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}