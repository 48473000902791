@import '@/styles/_variable.scss';

.contentAccordion {
    position: relative;
    text-align: center;
    padding-block-end: 8.5em;
    padding-block-start: 20px;
    --target-color: var(--color-yellow);
    background: url('/images/bg-1.png') no-repeat 54% -9%;
    background-size: 120% auto;

    h1 {
        font-size: 1.8461em;
        line-height: 1.4166;
        color: var(--color-black);
        font-family: var(--font-parisienne);
        font-weight: 400;
        margin-bottom: 0.54em;
        line-height: 42px;
    }

    .subTitle {
        box-shadow: var(--box-shadow);
        background-color: var(--target-color);
        color: var(--color-white);
        text-decoration: none;
        font-size: 2em;
        line-height: 1.1;
        font-weight: 300;
        border-radius: 0.861em;
        padding-inline-start: 0.8461em;
        padding-inline-end: 0.384em;
        padding-block: 0.384em;
        margin-block-end: 1.269em;
        margin-inline: 15px;

        span {
            flex-grow: 1;
        }

        svg {
            --circle-color: var(--color-white);
            --arrow-color: var(--target-color);
            block-size: 1.1538em;
            inline-size: 1.1538em;
        }
    }
    .lists {
        position: relative;
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        .cnt {
            position: relative;
            padding-inline: var(--size-16);
            width: 100%;
            padding-block-end: 1.5em;
            margin-block-end: -1.5em;
            &:before {
                background-color: var(--target-color);
                border-top-left-radius: var(--size-24);
                border-top-right-radius: var(--size-24);
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
            &:last-child {
                flex-grow: 1;
                margin-block-end: 0em;
                &:before {
                    border-radius: var(--size-24);
                }
            }
            > div {
                position: relative;
            }
            &.active {
                button svg {
                    rotate: 180deg;
                }
            }
        }
        .content {
            // display: none;
            color: var(--color-white);
            border-top: 1px solid rgba($color: #FFFFFF, $alpha: 0.8);
            padding-block: 10px;
        }
        button {
            display: flex;
            border: none;
            background: none;
            justify-content: space-between;
            width: 100%;
            font-size: 2em;
            line-height: 1.153;
            color: var(--color-white);
            font-weight: 300;
            padding-block: 0.58em;
            svg {
                width: 1.153em;
                height: 1.153em;
            }
        }
    }

    .wrap {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        height: 100%;
    }

}