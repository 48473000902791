@import '@/styles/_variable.scss';

.galleryContent {
    position: relative;
    text-align: center;
    padding-block-end: 9em;
    padding-block-start: 20px;
    --target-color: var(--color-yellow);
    background: url('/images/bg-1.png') no-repeat 54% -9%;
    background-size: 120% auto;

    .wrap {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }

    h1 {
        font-size: 1.8461em;
        line-height: 1.4166;
        color: var(--color-black);
        font-family: var(--font-parisienne);
        font-weight: 400;
        margin-bottom: 0.54em;
        line-height: 42px;
    }

    .contnet {
        color: var(--color-dark-gray);
        padding: var(--size-18);
        background-color: var(--color-white);
        border-radius: var(--size-12);
        margin-bottom: 1.8461em;
    }    

    
    .subTitle {
        box-shadow: var(--box-shadow);
        background-color: var(--target-color);
        color: var(--color-white);
        text-decoration: none;
        font-size: 2em;
        line-height: 1.1;
        font-weight: 300;
        border-radius: 0.861em;
        padding-inline-start: 0.8461em;
        padding-inline-end: 0.384em;
        padding-block: 0.384em;
        margin-block-end: 0.80em;
        margin-inline: 15px;
    }  
    .list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-right: -4px;
            a {
                display: block;
            }
            img {
                display: block;
            }
        }
    }
}