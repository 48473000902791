@import '@/styles/_variable.scss';

.titleContent {
    position: relative;
    text-align: center;
    padding-block-end: 10em;
    padding-block-start: 20px;
    --target-color: var(--color-yellow);
    background: url('/images/bg-1.png') no-repeat 54% -9%;
    background-size: 120% auto;

    .wrap {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }

    h1 {
        font-size: 1.8461em;
        line-height: 1.4166;
        color: var(--color-black);
        font-family: var(--font-parisienne);
        font-weight: 400;
        margin-bottom: 0.54em;
        line-height: 42px;
    }

    .cnt {
        color: var(--color-dark-gray);
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.3em;
        h3 {
            font-weight: 300;
            font-size:  3.0769em;
            line-height: 1.15;
            margin-bottom: 0.5em;
            letter-spacing: 0.035em;
        }
        .contnet {
            background-color: var(--color-white);
            margin-inline: auto;
            border-radius: var(--size-12);
            padding: var(--size-18) var(--size-5);
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: var(--size-12);
            color: rgba(11, 8, 4, 0.75);
            p {
                a {
                    word-break: break-all;
                }
            }
            strong {
                font-weight: 700;
            }
            img {
                max-width: 68%;
                line-height: 1;
            }
        }    
    }

    
    .subTitle {
        box-shadow: var(--box-shadow);
        background-color: var(--target-color);
        color: var(--color-white);
        text-decoration: none;
        font-size: 2em;
        line-height: 1.1;
        font-weight: 300;
        border-radius: 0.861em;
        padding-inline-start: 0.8461em;
        padding-inline-end: 0.384em;
        padding-block: 0.384em;
        margin-block-end: 0.7em;
        margin-inline: 15px;

        span {
            flex-grow: 1;
        }

        svg {
            --circle-color: var(--color-white);
            --arrow-color: var(--target-color);
            block-size: 1.1538em;
            inline-size: 1.1538em;
        }
    }  
}