@import '@/styles/_variable.scss';

.videoContent {
    position: relative;
    text-align: center;
    padding-block-end: 8.76em;
    padding-block-start: 20px;
    --target-color: var(--color-yellow);
    background: url('/images/bg-1.png') no-repeat 54% -9%;
    background-size: 120% auto;

    .wrap {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }

    h1 {
        font-size: 1.8461em;
        line-height: 1.4166;
        color: var(--color-black);
        font-family: var(--font-parisienne);
        font-weight: 400;
        margin-bottom: 0.54em;
        line-height: 42px;
    }

    .cnt {
        color: var(--color-dark-gray);
        background-color: var(--color-white);
        border-radius: var(--size-12);
        box-shadow: var(--box-shadow); 
        overflow: hidden;
        .video {
            background-color: #D9D9D9;
            border-radius: var(--size-12);
            overflow: hidden;
            position: relative;
            &::before {
                content: '';
                display: block;
                padding-bottom: 56%;
                background-color: #D9D9D9;
                border-radius: inherit;
            }
            video {
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                border-radius: inherit;
            }
        }
        .contnet {
            padding: var(--size-18) 10px;
        }    
    }

    
    .subTitle {
        box-shadow: var(--box-shadow);
        background-color: var(--target-color);
        color: var(--color-white);
        text-decoration: none;
        font-size: 2em;
        line-height: 1.1;
        font-weight: 300;
        border-radius: 0.861em;
        padding-inline-start: 0.8461em;
        padding-inline-end: 0.384em;
        padding-block: 0.384em;
        margin-block-end: 1.05em;
        margin-inline: 15px;

        span {
            flex-grow: 1;
        }

        svg {
            --circle-color: var(--color-white);
            --arrow-color: var(--target-color);
            block-size: 1.1538em;
            inline-size: 1.1538em;
        }
    }  
}